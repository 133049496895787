import FooterComponent from "components/Layout/FooterComponent";
import SuccessComponent from "components/SuccessComponent";
import React from "react";

const Success = () => {
  return (
    <>
      <SuccessComponent />
      <FooterComponent />
    </>
  );
};

export default Success;
