import DashboardComponent from "components/DashboardComponent";
import FooterComponent from "components/Layout/FooterComponent";
import "./style.css";

function Dashboard() {
  return (
    <>
      <DashboardComponent />
      <FooterComponent />
    </>
  );
}

export default Dashboard;
