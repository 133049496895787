import React from "react";
import boltIcon from "assets/img/boltIcon.svg";
import bbLogoShadow from "assets/img/bbLogoShadow.svg";
import BlockboltLogo from "assets/img/BlockboltLogo.svg";
import boltGif from "assets/img/boltGif.gif";
import "./style.css";

const HeaderComponent = ({ onReloadContent }) => {
  const getPathname = window.location.pathname;

  const onCreateLink = () => {
    if (getPathname === "/") {
      onReloadContent();
    } else {
      window.open("https://unifiedlink.blockbolt.io/");
    }
  };

  return (
    <>
      <div className="boltIcon">
        <img src={boltIcon} alt="" />
      </div>
      <div className="bbLogoShadow">
        <img src={bbLogoShadow} alt="" />
      </div>

      <header className="headerSection">
        <a
          href={"https://unifiedlink.blockbolt.io/"}
          rel="noreferrer"
          alt="Powered by Blockbolt, Contactless Decentralized Payment Protocol"
        >
          <img src={BlockboltLogo} className="bbLogo" alt="BlockBolt Logo" />{" "}
        </a>
        <h1>Unified Payment Link</h1>
        <span>Generator</span>
        <img src={boltGif} alt="boltGif" className="boltGif" />
      </header >

    </>
  );
};

export default HeaderComponent;
