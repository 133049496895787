import React from "react";
import classnames from "classnames";
import { useStyles } from "./style";

const ButtonComponent = ({
  children,
  onClick,
  className,
  disabled,
  type,
  title,
  bgColor,
  color,
  btn,
  btnLight,
  extraClass,
  style,
  preFixIcon,
  postFixIcon,
  peNone,
  buttonRef,
  theme,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <button
      disabled={loading ? loading : disabled}
      title={title}
      type={type}
      ref={buttonRef}
      className={classnames(
        classes.btn,
        className,
        extraClass,
        btn && classes.btn,
        btnLight && classes.btnLight,
      )}
      onClick={onClick}
      style={style}
      {...rest}
    >
      {preFixIcon}
      {children}
      {postFixIcon}
    </button>
  );
};

export default ButtonComponent;
