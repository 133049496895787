import { createUseStyles } from "react-jss";
import mediaQuery from "assets/breakpoints";
export const useStyles = createUseStyles({
  btn: {
    display: "inline-flex",
    alignItems: "center",
    gap: "5px",
    background: "var(--logoGradient)",
    color: "var(--white)",
    fontSize: "16px",
    padding: "15px 40px",
    border: "none",
    borderRadius: "8px",
    fontWeight: "550",
    letterSpacing: "0.2px",
    cursor: "pointer",
    transition: "0.3s ease-in-out",
    whiteSpace: "nowrap",
    [mediaQuery.SCREEN_SM_MAX]: {
      padding: "10px 25px",
      fontSize: "14px",
    },
    "&:hover": {
      background: "var(--revLogoGradient)",
    },
  },
  btnLight: {
    background: "var(--whiteOpacity05)",
    "&:hover": {
      background: "var(--whiteOpacity10)",
    },
  },
});
