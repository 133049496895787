import "./index.css";

const NotRecognize = () => {
  return (
    <div className="not-recognize-wrapper">
      <p>Something went wrong!</p>
    </div>
  );
};
export default NotRecognize;
