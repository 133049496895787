import { Tabs } from "antd";
import React from "react";

const TabsComponent = ({ defaultKey, data, onChange, tabDirection }) => {
    return (
        <Tabs
            defaultActiveKey={defaultKey}
            items={data}
            onChange={onChange}
            tabPosition={tabDirection}
        />
    );
};

export default TabsComponent;
