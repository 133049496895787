import { Image, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SuccessLoader from "assets/img/payment-success.gif";
import React, { useEffect, useState } from "react";
import { shorten_address, shorten_digest, toastMsg } from "utils/helper";
import { CopyIcon } from "assets/svg";
import "./style.css";
import { ToastContainer } from "react-toastify";
import HeaderComponent from "components/Layout/HeaderComponent";


const SuccessComponent = () => {
  const [generateLink, setGenerateLink] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [generateContent, setGenerateContent] = useState({});

  const onCopyContent = (getLink) => {
    navigator.clipboard.writeText(getLink);
    toastMsg("Copied!");
  };

  const manageParams = (searchParams) => {
    setShowLoader(true);
    const params = {
      order_id: searchParams.get("order_id"),
      digest: searchParams.get("main_digest") || searchParams.get("test_digest"),
    };
    if (params && params.order_id && params.digest) {
      setShowLoader(false);
      setGenerateContent(params);
      const generateRedirectLink = searchParams.get("main_digest") ? `https://suivision.xyz/txblock/${params.digest}` : `https://testnet.suivision.xyz/txblock/${params.digest}`
      setGenerateLink(generateRedirectLink);
    } else {
      setShowLoader(false);
      toastMsg("Something went wrong")
    }
  };

  const onVerifyEntries = () => {
    try {
      const input = window.location;
      const searchParams = new URLSearchParams(window.location.search);
      manageParams(searchParams);
    } catch (error) {
      toastMsg("Url doesn't match");
    }
  };

  useEffect(() => {
    onVerifyEntries();
  }, []);

  return (
    <>
      <ToastContainer />
      <main className="mainSection">
        <div className="container">
          <div className="mainSectionbody">
            <HeaderComponent />
            {showLoader && (
              <div className="centerWrapper">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              </div>
            )}
            {!showLoader && generateContent && Object.keys(generateContent).length > 0 && (
              <>
                <div className="successSection">
                  <div className="successLoader">
                    <Image
                      src={SuccessLoader}
                      preview={false}
                    />
                  </div>
                  <h1>Transaction Completed</h1>
                  {generateLink !== "" && (
                    <div className="successOutput">
                      <div className="successOutputItm">
                        <p>
                          Order Id: <span>{generateContent.order_id}</span>
                        </p>
                      </div>
                      <div className="successOutputItm">
                        <span>Digest:{" "}</span>
                        <a href={generateLink} target="_blank" rel="noreferrer">
                          {shorten_address(generateContent.digest)}
                        </a>
                        <div onClick={() => onCopyContent(generateLink)}>
                          <CopyIcon />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="successContentWrapper">
                    <div className="contentWrapper">
                      <p>We are pleased to present the demo success page designed specifically for those who wish to seamlessly redirect their customers after completing a payment transaction.
                        This page will provide you with the order ID and a digest. You have the option to retrieve comprehensive order information using the digest.
                        Additionally, we are excited to offer an API that allows you to access further details from the BlockBolt data resource.</p>
                      <p className="highlight">We can offer tailored decentralized multichain payment solutions for your website or dApp if you require a payment gateway.</p>
                      <p>For any inquiries or additional information, please do not hesitate to contact us at <a href="mailto:support@blockbolt.io">support@blockbolt.io</a> or alternatively, you can open a support ticket on our Discord channel: <a href="https://discord.com/invite/Fb8CA6ny67" target="_blank" rel="noreferrer">https://discord.com/invite/Fb8CA6ny67</a>.</p>
                      <h4>Thank you for choosing our services, Blockbolt Inc.</h4>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default SuccessComponent;
