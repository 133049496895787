export const coinMainList = [
  {
    name: "SUI",
    image: "https://s2.coinmarketcap.com/static/img/coins/64x64/20947.png",
    symbol: "SUI",
  },
  {
    name: "USDC",
    image: "https://suivision.xyz/images/coins/usdc.png",
    symbol: "USDC",
  },
  {
    name: "NAVX",
    image:
      "https://ctiyulirqff3b6evprwjwpr7c7wgimilhugii3cnat72wq3h7ira.arweave.net/FNGKLRGBS7D4lXxsmz4_F-xkMQs9DIRsTQT_q0Nn-iI",
    symbol: "NAVX",
  },
  {
    name: "Scallop",
    image:
      "https://vrr7y7aent4hea3r444jrrsvgvgwsz6zi2r2vv2odhgfrgvvs6iq.arweave.net/rGP8fARs-HIDcec4mMZVNU1pZ9lGo6rXThnMWJq1l5E",
    symbol: "SCA",
  },
  {
    name: "FlowX",
    image: "https://imagedelivery.net/cBNDGgkrsEA-b_ixIp9SkQ/flx.svg/public",
    symbol: "FLX",
  },
  {
    name: "FUD",
    image: "https://imagedelivery.net/cBNDGgkrsEA-b_ixIp9SkQ/fud.png/public",
    symbol: "FUD",
  },
  {
    name: "Sacabam",
    image:
      "https://imagedelivery.net/cBNDGgkrsEA-b_ixIp9SkQ/sacabam.png/public",
    symbol: "SCB",
  },
];

export const coinTestList = [
  {
    name: "SUI",
    image: "https://s2.coinmarketcap.com/static/img/coins/64x64/20947.png",
    symbol: "SUI",
  },
];

export const chainList = [
  {
    name: "Mainnet",
  },
  {
    name: "Testnet",
  },
];

export const requiredField = {
  sender_address: "",
  merchant_address: "",
  merchant_name: "",
  amount: "",
  blockchain: "",
  network: "Mainnet",
  coin: "",
  back_url: "",
};

export const requiredWOAField = {
  merchant_address: "",
  merchant_name: "",
  amount: "",
};

export const requiredWAField = {
  sender_address: "",
  merchant_address: "",
  merchant_name: "",
  amount: "",
};
