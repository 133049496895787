/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import { Checkbox, Col, Image, Modal, Row, Select, Tooltip } from "antd";
import { JsonRpcProvider, mainnetConnection } from "@mysten/sui.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyIcon, LinkIcon, GenerateIcon, SuiIcon, NetworkIcon, CheckIcon, DfinityFoundationLogo, SuiFoundationLogo, GrantIcon, LocationIcon, PlayButtonIcon } from "assets/svg";
import HeaderComponent from "components/Layout/HeaderComponent";
import SingaporeVideo from "assets/img/videoplayback.mp4"
import {
  baseUrl,
  checkAllRequiredFieldsWithKey,
  toastMsg,
  validateAddress,
  manageCoinType,
  validateURL,
  successUrl,
} from "utils/helper";
import {
  chainList,
  coinMainList,
  coinTestList,
  requiredField,
  requiredWAField,
  requiredWOAField,
} from "./constant";
import TextBoxComponent from "components/FormsComponent/TextBoxComponent";
import ButtonComponent from "components/FormsComponent/ButtonComponent";
import TabsComponent from "components/FormsComponent/TabsComponent";

function DashboardComponent() {
  const { Option } = Select;
  const [errors, setErrors] = useState();
  const [haveToAdd, setHaveToAdd] = useState(false);
  const [haveToCallback, setHaveToCallback] = useState(false);
  const [generateLink, setGenerateLink] = useState("");
  const [generateOrderId, setGenerateOrderId] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [showGrantVideo, setShowGrantVideo] = useState(false);
  const [formData, setFormData] = useState({
    ...requiredField,
  });

  const provider = new JsonRpcProvider(mainnetConnection); // or testnetConnection // devnet connection

  const resolveNameService = async (name) => {
    try {
      const address = await provider.resolveNameServiceAddress({ name });
      return address;
    } catch (e) {
      return null;
    }
  };

  const onCopyContent = (getLink) => {
    navigator.clipboard.writeText(getLink);
  };

  const checkAllErrors = () => {
    if (haveToAdd) {
      let errorObject = {
        ...checkAllRequiredFieldsWithKey(requiredWAField, formData),
      };
      setErrors({ ...errors, ...errorObject });
      return Object.values(errorObject).every((errorMsg) => !errorMsg);
    } else {
      let errorObject = {
        ...checkAllRequiredFieldsWithKey(requiredWOAField, formData),
      };
      setErrors({ ...errors, ...errorObject });
      return Object.values(errorObject).every((errorMsg) => !errorMsg);
    }
  };

  const onReloadContent = () => {
    setFormData({ ...requiredField });
    setHaveToCallback(false);
    setHaveToAdd(false);
  };

  const removeError = ({ target: { name } }) => {
    setErrors({ ...errors, [name]: "" });
  };

  function onCheckedAddress(e) {
    setHaveToAdd(e.target.checked === true ? true : false);
  }

  function onCheckedCallback(e) {
    setHaveToCallback(e.target.checked === true ? true : false);
  }

  function handlePaste(event) {
    event.preventDefault(); // Prevent the default paste behavior
  }

  const onNetworkChange = (key) => {
    if (key == "0") {
      setFormData({ ...formData, coin: "", network: "Mainnet" });
    } else {
      setFormData({ ...formData, coin: "", network: "Testnet" });
    }
  };

  const handleUrlChange = ({ target: { name, value } }) => {
    const urlRegex =
      /^(ftp|http|https):\/\/([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;
    setFormData({ ...formData, [name]: value });
    if (urlRegex.test(value)) {
      setErrors({ ...errors, [name]: "" });
    } else {
      setErrors({ ...errors, [name]: `Please enter correct url` });
    }
  }

  const handleAmount = ({ target: { name, value } }) => {
    if (value !== " " && !/^\d*\.?\d*$/.test(value)) {
      setErrors({
        ...errors,
        [name]: "Amount should be numeric",
      });
      return;
    }
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const onCoinChange = (getValue) => {
    setFormData({ ...formData, coin: getValue });
  };

  const handleAddress = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
    const sAddress = validateAddress(value);
    if (!sAddress) {
      setErrors({
        ...errors,
        [name]: "Please fillup correct wallet address",
      });
    } else {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const checkGenerateSAdd = (getKey, getType) => {
    let removedSpacesText = formData.merchant_name
      .split(" ")
      .join("");
    if (haveToAdd) {
      return `merchant_address=${formData.merchant_address}&customer_address=${formData.sender_address
        }&merchant_name=${removedSpacesText}&order_id=${getKey}&amount=${formData.amount
        }&network=${formData.network}&blockchain=sui&coin_name=${formData.coin
        }&coin_type=${getType}${haveToCallback ? `&back_url=${formData.back_url}` : ""
        }`;
    } else {
      return `merchant_address=${formData.merchant_address}&merchant_name=${removedSpacesText
        }&order_id=${getKey}&amount=${formData.amount}&network=${formData.network
        }&blockchain=sui&coin_name=${formData.coin}&coin_type=${getType}${haveToCallback ? `&back_url=${formData.back_url}` : ""
        }`;
    }
  };

  const onGenerateLink = () => {
    const getType = manageCoinType(formData.coin);
    if (getType) {
      const randomKey = Math.floor(100000 + Math.random() * 900000);
      const generateURL = checkGenerateSAdd(randomKey, getType);
      const manageNewUrl = `${baseUrl}${generateURL}`;
      setGenerateOrderId(randomKey);
      setGenerateLink(manageNewUrl);
      toastMsg("Successfully created!");
      onReloadContent();
      setIsDisable(true);
      setBtnLoader(false);
    }
  };

  const handleSubmit = async () => {
    if (checkAllErrors()) {
      if (!formData.network) {
        toastMsg("Please select a network");
        return;
      }
      if (!formData.coin) {
        toastMsg("Please select a coin");
        return;
      }
      setBtnLoader(true);
      if (formData.sender_address == formData.merchant_address) {
        setBtnLoader(false);
        toastMsg("Both address should be different!");
        return;
      }
      let addressField = {
        merchant_address: "",
        sender_address: ""
      };
      if (
        formData.merchant_address &&
        formData.merchant_address.includes(".sui")
      ) {
        const address = await resolveNameService(formData.merchant_address);
        if (!address) {
          setBtnLoader(false);
          toastMsg(`Could not find ${formData.merchant_address}`);
          return;
        }
        addressField = {
          ...addressField,
          merchant_address: address
        };
      }
      if (formData.sender_address && formData.sender_address.includes(".sui")) {
        const address = await resolveNameService(formData.sender_address);
        if (!address) {
          setBtnLoader(false);
          toastMsg(`Could not find ${formData.sender_address}`);
          return;
        }
        if (addressField.merchant_address == address) {
          setBtnLoader(false);
          toastMsg("Both address should be different!");
          return;
        }
      }
      if (haveToCallback) {
        if (formData.back_url == "") {
          setBtnLoader(false);
          setErrors({
            ...errors,
            ["back_url"]: "This field is required",
          });
          return;
        } else {
          const abc = validateURL(formData.back_url);
          if (!abc) {
            setBtnLoader(false);
            setErrors({
              ...errors,
              ["back_url"]: "Enter a valid callback url",
            });
            return;
          }
        }
      }
      onGenerateLink();
    }
  };

  const renderBtnElement = () => {
    return (
      <>
        {
          isDisable ? (
            <>
              Link Generated
              <CheckIcon />
            </>
          ) : (
            <>
              Generate Link
              < GenerateIcon />
            </>
          )
        }
      </>
    )
  }

  const networkItems = [
    {
      key: "0",
      label: "Mainnet",
      icon: <NetworkIcon />,
    },
    {
      key: "1",
      label: "Testnet",
      icon: <NetworkIcon />,
    },
  ];

  return (
    <>
      <ToastContainer />
      <main className="mainSection">
        <div className="container">
          <div className="mainSectionbody">
            <HeaderComponent />
            <div className="formWrapper">
              <Row gutter={{ xs: 18, sm: 18, md: 18, lg: 18 }}>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <TextBoxComponent
                    type="text"
                    placeholder="Enter Merchant (Receiver) Name"
                    mandatory
                    name="merchant_name"
                    onFocus={removeError}
                    onChange={handleInputChange}
                    value={formData && formData.merchant_name}
                    error={errors && errors.merchant_name}
                    disabled={isDisable}
                  />
                </Col>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <TextBoxComponent
                    type="text"
                    placeholder="Enter Merchant (Receiver) Address"
                    mandatory
                    name="merchant_address"
                    onFocus={removeError}
                    onChange={handleAddress}
                    tooltip={true}
                    tooltipTitle={"This filed supports SNS! You can use sui domain name instead of sui wallet address."}
                    value={formData && formData.merchant_address}
                    error={errors && errors.merchant_address}
                    disabled={isDisable}
                  />
                </Col>
              </Row>
              <Row gutter={{ xs: 18, sm: 18, md: 18, lg: 18 }}>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="networkSelection formFields">
                    <SuiIcon />
                    <p>SUI Network</p>
                  </div>
                </Col>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <TextBoxComponent
                    type="text"
                    placeholder="Enter Amount"
                    mandatory
                    name="amount"
                    onPaste={handlePaste}
                    onFocus={removeError}
                    onChange={handleAmount}
                    value={formData && formData.amount}
                    error={errors && errors.amount}
                    disabled={isDisable}
                  />
                </Col>
              </Row>
              <Row gutter={{ xs: 18, sm: 18, md: 18, lg: 18 }}>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="formFields">
                    <TabsComponent
                      defaultKey={"0"}
                      data={networkItems}
                      onChange={onNetworkChange}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="formFields">
                    <Select
                      title="Coin"
                      placeholder="Select Coin"
                      onChange={(value) => onCoinChange(value.toString())}
                      autoComplete="new-state"
                      value={formData.coin || undefined}
                      disabled={isDisable || formData.network == "" ? true : false}
                    >
                      {formData.network === "Testnet" ? (
                        <>
                          {coinTestList.map((coin) => (
                            <Option key={coin.name} value={coin.name}>
                              <span className="coin-image">
                                <Image src={coin.image} preview={false} />
                              </span>
                              <span>{coin.name}</span>
                            </Option>
                          ))}
                        </>
                      ) : (
                        <>
                          {coinMainList.map((coin) => (
                            <Option key={coin.name} value={coin.name}>
                              <span className="coin-image">
                                <Image src={coin.image} preview={false} />
                              </span>
                              <span>{coin.name}</span>
                            </Option>
                          ))}
                        </>
                      )}
                    </Select>
                  </div>
                </Col>
              </Row>
              <div className="checkboxWrapper">
                <div className="checkboxWrapperItm">
                  <Checkbox
                    onChange={onCheckedAddress}
                    className="checkLabel"
                    checked={haveToAdd}
                  >
                    Want to add customer address?
                  </Checkbox>
                </div>
                <Row gutter={{ xs: 18, sm: 18, md: 18, lg: 18 }}>
                  <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    {haveToAdd && (
                      <TextBoxComponent
                        type="text"
                        placeholder="Enter Customer Address"
                        mandatory
                        name="sender_address"
                        onFocus={removeError}
                        onChange={handleAddress}
                        tooltip={true}
                        tooltipTitle={"This filed supports SNS! You can use sui domain name instead of sui wallet address."}
                        value={formData && formData.sender_address}
                        error={errors && errors.sender_address}
                        disabled={isDisable}
                      />
                    )}
                  </Col>
                </Row>
                <div className="checkboxWrapperItm">
                  <Checkbox
                    onChange={onCheckedCallback}
                    className="checkLabel"
                    checked={haveToCallback}
                  >
                    Want to add callback URL?
                  </Checkbox>
                </div>
                <Row gutter={{ xs: 18, sm: 18, md: 18, lg: 18 }}>
                  <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    {haveToCallback && (
                      <>
                        <TextBoxComponent
                          type="text"
                          placeholder="Enter Callback URL"
                          mandatory
                          name="back_url"
                          onFocus={removeError}
                          onChange={handleUrlChange}
                          value={formData && formData.back_url}
                          error={errors && errors.back_url}
                          disabled={isDisable}
                        />
                        <div className="demoSuccess">
                          <div className="linkWrapper">
                            <LinkIcon />
                            <p>{successUrl && successUrl}</p>
                            <Tooltip placement="rightBottom" title="Copied" trigger="click">
                              <span className="copyIcon svgIcon" onClick={() => onCopyContent(successUrl)}>
                                <CopyIcon />
                              </span>
                            </Tooltip>
                          </div>
                          <span>*We have created this demo success page for better understanding</span>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
            <div className="buttonContainer">
              <ButtonComponent
                onClick={() => !isDisable && handleSubmit()}
              >
                {renderBtnElement()}
              </ButtonComponent>
              {
                isDisable && (
                  <ButtonComponent btnLight onClick={() => { setGenerateLink(""); setIsDisable(false) }}>
                    Reset
                  </ButtonComponent>
                )
              }
            </div>
            {isDisable && (
              <>
                <div className="formoutput">
                  <div className="left">
                    <LinkIcon />
                    <a href={generateLink} rel="noreferrer" target="_blank">
                      {generateLink}
                    </a>
                    <Tooltip placement="rightBottom" title="Copied" trigger="click">
                      <div className="svgIcon" onClick={() => onCopyContent(generateLink)}>
                        <CopyIcon />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="right">
                    <p>Order Id: <span>{generateOrderId}</span></p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="container">
          <div className="mainSectionFooter">
            <Row gutter={10} >
              <Col span={12} className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                <div className="left">
                  <p><GrantIcon />Granted By</p>
                  <div className="content">
                    <SuiFoundationLogo />
                    <span className="separator"></span>
                    <DfinityFoundationLogo />
                  </div>
                </div>
              </Col>
              <Col span={12} className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                <div className="right">
                  <p><LocationIcon />Blockbolt at</p>
                  <div className="content">
                    <span className="separator"></span>
                    <span>Sui Builder House, Singapore</span>
                    <ButtonComponent btnLight onClick={() => setShowGrantVideo(true)} preview={false}>
                      Watch Video <PlayButtonIcon />
                    </ButtonComponent>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </main>
      <Modal
        open={showGrantVideo}
        onCancel={() => setShowGrantVideo(false)}
        footer=""
      >
        <iframe src={SingaporeVideo} width={"100%"} height={"500px"} style={{ border: 0 }} />
      </Modal>
    </>
  );
}

export default DashboardComponent;
