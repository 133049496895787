import React from "react";
import "./style.css";
const FooterComponent = () => {
  return (
    <section className="footerSection">
      <p>Payments are processed by BlockBolt Inc. All sales final, refunds handled by the merchant.
      </p>
      <span>© BlockBolt Inc. All rights reserved.</span>
    </section>
  );
};

export default FooterComponent;
