import { ConfigProvider } from "antd";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { WalletKitProvider } from "@mysten/wallet-kit";
import NotRecognize from "pages/NotRecognize";
import Dashboard from "pages/Dashboard";
import Success from "pages/Success";
import "./assets/global.css";

function App() {
  return (
    <ConfigProvider>
      <BrowserRouter>
        <WalletKitProvider
          features={["sui:signTransactionBlock"]}
          enableUnsafeBurner
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/success" element={<Success />} />
            <Route path="/*" element={<NotRecognize />} />
          </Routes>
        </WalletKitProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
