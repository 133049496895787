import { toast } from "react-toastify";
import BlockboltLoader from "../assets/img/BlockboltLoader.gif";
import CryptoJS from "crypto-js";

export const baseUrl = "https://pay.blockbolt.io/blockbolt:payment?";
export const successUrl = "https://unifiedlink.blockbolt.io/success";
export const secretKey = "zQtCMFuNj74fLwE2jsE465atVHwMdeck";

export function encrypted_data(data, key) {
  const ciphertext = CryptoJS.AES.encrypt(data, key).toString();
  return ciphertext;
}

export function decrypted_data(ciphertext, key) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, key);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}

export const checkAllRequiredFieldsWithKey = (fields, values) =>
  Object.entries(fields).reduce((acc, [key]) => {
    const message = !values[key] ? "This field is required" : "";
    return { ...acc, [key]: message };
  }, {});

export const validateURL = (url) => {
  // const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  const urlPattern =
    /\b(?:https?|ftp):\/\/[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[-A-Za-z0-9+&@#\/%=~_|]/;
  if (urlPattern.test(url)) {
    return true;
  } else {
    return false;
  }
};

export const backURL = "https://unifiedlink.blockbolt.io";
export const SUI_TESTNET_PACKAGE_ID =
  "0x2b4232ac9939d5569a8ef75f951c8448749b02a25143000d89c336238ec69558";
export const SUI_MAINNET_PACKAGE_ID =
  "0x61ca74ccba882af69f09011ab581ce2e3ed5cd202233a0af3a2eb5c0a69e166b";
export const PACKAGE_TESTNET =
  "0x2b4232ac9939d5569a8ef75f951c8448749b02a25143000d89c336238ec69558";
export const RPC_TESTNET =
  "https://rpc.ankr.com/sui_testnet/c8f645fb306763c1564f37ffa22b2cac81d7e55081aa6aa8abce3931d55d4888";
export const RPC_MAINNET =
  "https://rpc.ankr.com/sui/c8f645fb306763c1564f37ffa22b2cac81d7e55081aa6aa8abce3931d55d4888";
export const SUI_ADDRESS_REGEX = /^0x[a-fA-F0-9]{64}$/;
export const clientNetwork = "mainnet";
export const clientChain = "sui";
export const ORD_ID_MSG = "OOPS! order id was not match";

export function shorten_digest(text, start = 10, end = 6, separator = "...") {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

export function BoltLoader() {
  return (
    <div>
      <img className="loader-logo lds-ripple" src={BlockboltLoader} alt="" />
    </div>
  );
}

export function toastMsg(data) {
  toast(data, {
    position: "top-left",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export function shorten_address(text, start = 5, end = 4, separator = "...") {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

export const identifyDecimal = (getCoinName) => {
  switch (getCoinName) {
    case "FUD": {
      return 1_00_000;
    }
    case "FlowX": {
      return 1_00_000_000;
    }
    case "SUI": {
      return 1_000_000_000;
    }
    case "USDC": {
      return 1_000_000;
    }
    case "Sacabam": {
      return 1_000_00;
    }
    case "NAVX": {
      return 1_000_000_000;
    }
    default: {
      return 1_000_000_000;
    }
  }
};

export const validateAddress = (address) => {
  if (address.includes(".sui")) {
    return "SUI";
  } else {
    const suiRegex = /^0x[a-fA-F0-9]{64}$/;
    if (suiRegex.test(address)) {
      return "SUI";
    } else {
      return false;
    }
  }
};

export const manageCoinType = (coin) => {
  switch (coin) {
    case "SUI": {
      return "0x2::sui::SUI";
    }
    case "USDC": {
      return "0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN";
    }
    case "NAVX": {
      return "0xa99b8952d4f7d947ea77fe0ecdcc9e5fc0bcab2841d6e2a5aa00c3044e5544b5::navx::NAVX";
    }
    case "Scallop": {
      return "0x7016aae72cfc67f2fadf55769c0a7dd54291a583b63051a5ed71081cce836ac6::sca::SCA";
    }
    case "FlowX": {
      return "0x6dae8ca14311574fdfe555524ea48558e3d1360d1607d1c7f98af867e3b7976c::flx::FLX";
    }
    case "FUD": {
      return "0x76cb819b01abed502bee8a702b4c2d547532c12f25001c9dea795a5e631c26f1::fud::FUD";
    }
    case "Sacabam": {
      return "0x9a5502414b5d51d01c8b5641db7436d789fa15a245694b24aa37c25c2a6ce001::scb::SCB";
    }
  }
};
