import { createUseStyles } from "react-jss";
import mediaQuery from "assets/breakpoints.js";
export const useStyles = createUseStyles({
  formGrp: {
    marginBottom: "18px",
    [mediaQuery.SCREEN_MD_MAX]: {
      marginBottom: "15px",
    },
    "& .danger": {
      color: "var(--neon)",
      padding: "5px 0 0",
      fontSize: "14px",
    },
    "& .fgBox": {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      "& input": {
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "1px solid var(--whiteOpacity10)",
        outline: "none",
        borderRadius: "10px",
        fontSize: "18px",
        color: "var(--white)",
        padding: "20px 50px 20px 20px",
        transition: "0.3s ease-in-out",
        [mediaQuery.SCREEN_MD_MAX]: {
          fontSize: "16px",
          padding: "15px 40px 15px 15px",
        },
        "&:focus-visible": {
          outline: "none",
        },
        "&:focus": {
          borderColor: "var(--neon)",
        },
      },
      "& .tooltipIcon": {
        position: "absolute",
        right: "15px",
        top: "20px",
        cursor: "pointer",
        [mediaQuery.SCREEN_MD_MAX]: {
          top: "15px",
        },
      },
    },
  },
});
